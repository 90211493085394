import { defaultConfig, WrapperPositions } from "@czechtv/global-components";

import vseOCTLogoForDark from "./logo_ct_vse_o_ct_fordark.svg";
import vseOCTLogoForLight from "./logo_ct_vse_o_ct_forlight.svg";

const ABSOLUTE_DOMAIN = !!process ? process.env?.NEXT_PUBLIC_MAIN_NAVIGATION_URL_PREFIX : "";

export const projectGlobalMenuConfig = {
  ...defaultConfig,
  ...{
    logo: {
      title: "Vše o ČT",
      src: vseOCTLogoForLight.src,
      srcDark: vseOCTLogoForDark.src,
      href: "https://www.ceskatelevize.cz/vse-o-ct/",
    },
    defaultPosition: WrapperPositions.relative,
    style: {
      default: {
        backgroundColor: "transparent",
      },
    },
    superHomepageNavVisible: false,
    search: {
      queryParameter: "q",
      url: "https://www.ceskatelevize.cz/hledani/",
    },
    productMenu: {
      items: [
        {
          title: "Lidé",
          href: `${ABSOLUTE_DOMAIN}/lide/`,
        },
        {
          title: "Jak sledovat",
          href: `${ABSOLUTE_DOMAIN}/jak-sledovat/`,
        },
        {
          title: "Galerie a prodejna",
          href: `${ABSOLUTE_DOMAIN}/galerie-a-prodejna/`,
        },
        {
          title: "Spolupráce",
          href: `${ABSOLUTE_DOMAIN}/spoluprace/`,
        },
        {
          title: "Kariéra",
          href: `${ABSOLUTE_DOMAIN}/kariera/`,
          items: [
            {
              title: "Kariéra v ČT",
              href: `${ABSOLUTE_DOMAIN}/kariera/`,
            },
            {
              title: "Volná místa",
              href: "https://ceskatelevize.jobs.cz/",
            },
            {
              title: "Možnosti uplatnění",
              href: `${ABSOLUTE_DOMAIN}/kariera/moznosti-uplatneni/`,
            },
            {
              title: "Studenti a stáže",
              href: `${ABSOLUTE_DOMAIN}/kariera/studenti-a-staze/`,
            },
            {
              title: "O výběrovém řízení",
              href: `${ABSOLUTE_DOMAIN}/kariera/prubeh-vyberoveho-rizeni/`,
            },
            {
              title: "Benefity",
              href: `${ABSOLUTE_DOMAIN}/kariera/benefity-prace-v-ct/`,
            },
            {
              title: "Kontakty",
              href: `${ABSOLUTE_DOMAIN}/kariera/kontakty/`,
            },
            {
              title: "Ze života v ČT",
              href: `${ABSOLUTE_DOMAIN}/kariera/ze-zivota-v-ct/`,
            },
            {
              title: "Konkurzy",
              href: `${ABSOLUTE_DOMAIN}/kariera/konkurzy/`,
            },
          ],
        },
        {
          title: "Historie",
          href: `${ABSOLUTE_DOMAIN}/historie/`,
        },
        {
          title: "Pro média",
          href: `${ABSOLUTE_DOMAIN}/pro-media/`,
        },
        {
          title: "Kontakty",
          href: `${ABSOLUTE_DOMAIN}/kontakty/`,
        },
      ],
      hotlinks: [
        {
          title: "Vše o ČT",
          href: "https://www.ceskatelevize.cz/vse-o-ct/",
        },
      ],
    },
  },
};
