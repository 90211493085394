import "../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22czechtvPackagesOverrides%2Fstyles%2Fvanilla%2Fglobal%2Fglobal.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAE42WS2%2FbMAzH7%2FsUPK6AUojUw7Z2222HAcM67Do4jpu6y6tO0qYd9t0HJpbSmupatNJB%2FpF%2F8SG2oV%2Bvd%2FDnA8Bk8gurZX9T1zrA1XrfN%2B1Vvdp%2B69eT7%2B18v6h7Bdt6tZ1s2767%2FvTCAscWV%2B2y%2B7xezF43obHJl1296JrXDUyAHz8Zvmr6tl29fSk7Mvj%2FfdyI%2FtrOuv3ydd4H6OfT%2BmPlFaDXCsg5BXjxkioGSiv%2BGX8th69sGteYqSJjCkWlMl4Q9TMv6E1GZzoQrlCkvcKKBNIMiHEKSlSAVSGjmQ0QkldYlhmlNhLeclqMAixJOrqOV7YspVGBySRvngIzCohw2MbYTcIoypVGYl3C2MdR2Gaw2wAvD36%2FWYBFDLriK3IrZHK3jJDlhjkmRkKrCGmngDktmfXAeK%2BAFFhJbJIXBaffMXEXYyLOrOFru4yjPjoqHQdGjGWquU2hcViFZSwjuosvhl3YQgHZTAr2sRE5%2FGPNTabm9zGAQsHxBZaZlD%2B8Bzo8S9axM3QmEY8DZJXRyssOeIpOjlWjTPCozwhyVD7DYJoWQFZBLj1I6elwntMmOJNKzEjaBGdT87LaeRtzLr1s%2FnrexpxPzXB%2BZpkCYpyMiMeMpG3MxRnJ7zouAcUh6QoFcQkozklTKohLQNOUNk7YaQmoSRCl3OaczVKtKNUqx7Xv9HedauWGMlGuW3GeauVimYocd%2FNOrktvpEi1qjLc7cAVxalOVS55cZ7yJOE6%2BVyt4kg1dKpTbp5gGqn%2BVCeTu%2FsqQLk5jA7XAdCL000AsuL0LoAhcdoHsFqcbgNYqbYL4KTaPoCXavcBCqn2EKCUaocApVR7DFBJtafAk218TDoAotAjDIAkBIn4WCiSCYBGSJINgFZKugDopKQP0OynXTOZtk9d2%2FN%2FTJeo9KURw5YK9qCX29FxmSbPs7%2Bw%2BpIuQG8OgJsD0OYAMqoqb%2BfOdiZrVweQ0U0z7UZNrt1oFqA4pe3vP%2F%2FHMbOGCwAA%22%7D"
import "../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22components%2Fblocks%2FAttachedDocumentsBlock%2FAttachedDocumentsBlock.css.tsx.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAE61U247aMBR85yusXVUilYxygQDmpU%2F9jZWTnIC7jh3ZZtm0Quq39NP6JVUcJ82FCFbaSCCMJzNzjsdn9RJs2L7KlY9%2BLRAqqDoyQZBfvttPSnm6fKNqifFLsC%2FUidJAeOgrWnv1%2FmFxXaxahsAxvOMLy8yJoF1sIQhxpg3WpuKATVUCQUIKOPTl6kVJs4yJo131aENLmzFdcloRlHOwnD%2FO2rC8wqkUBoQhSJc0BZyAuQCIw41apmU4e53wbLWhV%2BMSqTJQWNGMnfV9NE1fj0qeRYZTyaUi6Pm7ferNugp8UbQkqP4eFBzZgl0Po7jx2NXftK4HX1t4LoXBmv0EgoJVEG4UFE3nBeATsOPJ1Bvt3xad04Lxioz74rX%2BCArqn878EFV5Aw%2BbiYfb%2BtH2AQe%2BNysbbL3u5YtjFVIVlH%2FgwHu%2BY%2Bv7ttTeG%2FQ94TJ97aUFq0a%2BzZDUzDApCFLAqWFv43z3VQlJIJcKnLoL8NPf33%2Be7pX%2BX4cmWvKzsTrOCw73jRsXnrUz1x5A6NZGlp3x%2BWBt%2BzkMfP%2FL4aF7aKOtDVVmwLb7%2FIi0L81exM2tazsB9lr%2B7NunNv6tgIxRpFMFIBAVGVoWTLSjLa5b69mSVsMJ%2BoEZuusm0LXPEzqeR8bS5%2B9Fth%2BDCSVkM6NGPiPnc3pFBrDNuC0TRDwmYsKGouObXLogvtG34bW662vrYG3AI3cWrVPMITczcZmLPYis0bn%2BA1o%2BmlZYBwAA%22%7D"
export var listWrap = '_15i9yfr0';
export var listWrapCont = '_15i9yfr4';
export var listWrapCont_p = '_15i9yfr5';
export var listWrapCont_span = '_15i9yfr6';
export var listWrapIcon = '_15i9yfr3';
export var listWrapLink = '_15i9yfr7';
export var listWrapLink_a = '_15i9yfr8';
export var listWrap_li = '_15i9yfr2';
export var listWrap_ul = '_15i9yfr1';