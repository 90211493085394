import "../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22czechtvPackagesOverrides%2Fstyles%2Fvanilla%2Fglobal%2Fglobal.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAE42WS2%2FbMAzH7%2FsUPK6AUojUw7Z2222HAcM67Do4jpu6y6tO0qYd9t0HJpbSmupatNJB%2FpF%2F8SG2oV%2Bvd%2FDnA8Bk8gurZX9T1zrA1XrfN%2B1Vvdp%2B69eT7%2B18v6h7Bdt6tZ1s2767%2FvTCAscWV%2B2y%2B7xezF43obHJl1296JrXDUyAHz8Zvmr6tl29fSk7Mvj%2FfdyI%2FtrOuv3ydd4H6OfT%2BmPlFaDXCsg5BXjxkioGSiv%2BGX8th69sGteYqSJjCkWlMl4Q9TMv6E1GZzoQrlCkvcKKBNIMiHEKSlSAVSGjmQ0QkldYlhmlNhLeclqMAixJOrqOV7YspVGBySRvngIzCohw2MbYTcIoypVGYl3C2MdR2Gaw2wAvD36%2FWYBFDLriK3IrZHK3jJDlhjkmRkKrCGmngDktmfXAeK%2BAFFhJbJIXBaffMXEXYyLOrOFru4yjPjoqHQdGjGWquU2hcViFZSwjuosvhl3YQgHZTAr2sRE5%2FGPNTabm9zGAQsHxBZaZlD%2B8Bzo8S9axM3QmEY8DZJXRyssOeIpOjlWjTPCozwhyVD7DYJoWQFZBLj1I6elwntMmOJNKzEjaBGdT87LaeRtzLr1s%2FnrexpxPzXB%2BZpkCYpyMiMeMpG3MxRnJ7zouAcUh6QoFcQkozklTKohLQNOUNk7YaQmoSRCl3OaczVKtKNUqx7Xv9HedauWGMlGuW3GeauVimYocd%2FNOrktvpEi1qjLc7cAVxalOVS55cZ7yJOE6%2BVyt4kg1dKpTbp5gGqn%2BVCeTu%2FsqQLk5jA7XAdCL000AsuL0LoAhcdoHsFqcbgNYqbYL4KTaPoCXavcBCqn2EKCUaocApVR7DFBJtafAk218TDoAotAjDIAkBIn4WCiSCYBGSJINgFZKugDopKQP0OynXTOZtk9d2%2FN%2FTJeo9KURw5YK9qCX29FxmSbPs7%2Bw%2BpIuQG8OgJsD0OYAMqoqb%2BfOdiZrVweQ0U0z7UZNrt1oFqA4pe3vP%2F%2FHMbOGCwAA%22%7D"
import "../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22utils%2Fvanilla%2Fshorthand.css.ts.vanilla.css%22%2C%22source%22%3A%22Ll8xMThmMXAzMCB7CiAgZGlzcGxheTogZmxleDsKICBmbGV4LXdyYXA6IHdyYXA7CiAgYWxpZ24taXRlbXM6IGNlbnRlcjsKfQouXzExOGYxcDMxIHsKICBkaXNwbGF5OiBmbGV4OwogIGZsZXgtZGlyZWN0aW9uOiBjb2x1bW47CiAgZmxleDogMTsKfQ%3D%3D%22%7D"
import "../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22components%2Fblocks%2FTestimonialBlock%2FTestimonialBlock.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAE62V0W7CIBSG732Ks4st60Ub6Fq19WZvsqCgsrXAEKdu2bsvRXRSWuuSxRgjcD7%2B858%2FbfKCdabMa4HgawRQE73iIp5LY2RdwgfRj3H8gotarwnB79Fs9D1KTiXYlqyICg7KaDYIUz4stbAdp2ZdAkbo3tt9srtLKUy8JDWvDm1cam%2B0Bzb8k5WAkzTXrG5WKy5YvGZ8tTYlpG7xKK4E1PxZyErqQGHhK8x%2BNZyuwN134OS0fEUxsoop36iKHEqYV3Lxdi7ZOVKGkKchDzX0KJjcICGPeps%2FXE7QSFUCUvvhoR4HrwilXKycuSenW0dF1CDP3zteK6kNEcZreHw1FlNvN809ifpohW9gEfLaBR0teYJaw%2BhpT0aA7KenscmNHDHASeEBKP%2BwiLnUlOlYE8q3m55GnmtGOYHNQjMmgAgKjzUXsXNknCG1jyws8Z8MQ5Pf2sl%2FXxbmXmGnQz0BaIHGDuREkq2RM7uwrNi%2BBBwUTFxBsx%2FvdPOEErL5DU5OfXSauZgPhEKH3Rb%2FRgoi1muh6gpH4MVfBtJJG0rNZDwNUoOvjaD7raGj0IvUd%2FUJOVcvRF0qKfIwvy1EjqZnxA9VvIMa%2FgYAAA%3D%3D%22%7D"
export var testimonial = '_1r4ptj90';
export var testimonialDescription = '_1r4ptj93';
export var testimonialHeading = '_1r4ptj95';
export var testimonialImageRounded = '_1r4ptj98';
export var testimonialItemRounded = '_1r4ptj97 _118f1p30';
export var testimonialPosition = '_1r4ptj94';
export var testimonialRectangle = '_1r4ptj91 _118f1p30';
export var testimonialTxt = '_1r4ptj96';
export var testimonialVideoRectangle = '_1r4ptj92';
export var testimonialVideoRounded = '_1r4ptj99';