import type { InferGetStaticPropsType, GetStaticProps } from "next";
import { getAllPages, getPageDetail } from "@utils/pages";
import { Page as PageComponent } from "@components/layouts/components/Page/Page";

const pageId = 1583;

type PageProps = {
  page: any;
  allPages: any[];
};

export const getStaticProps = (async (context) => {
  const allPages = await getAllPages();
  const pageBase = allPages.find((page) => page.id == pageId);
  const page = await getPageDetail(pageBase);
  return { props: { page, allPages } };
}) satisfies GetStaticProps<{
  page: PageProps;
}>;

export default function Page({ page, allPages }: InferGetStaticPropsType<typeof getStaticProps>) {
  return page ? <PageComponent page={page} allPages={allPages} /> : null;
}
