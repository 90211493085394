import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22czechtvPackagesOverrides%2Fstyles%2Fvanilla%2Fglobal%2Fglobal.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAE42WS2%2FbMAzH7%2FsUPK6AUojUw7Z2222HAcM67Do4jpu6y6tO0qYd9t0HJpbSmupatNJB%2FpF%2F8SG2oV%2Bvd%2FDnA8Bk8gurZX9T1zrA1XrfN%2B1Vvdp%2B69eT7%2B18v6h7Bdt6tZ1s2767%2FvTCAscWV%2B2y%2B7xezF43obHJl1296JrXDUyAHz8Zvmr6tl29fSk7Mvj%2FfdyI%2FtrOuv3ydd4H6OfT%2BmPlFaDXCsg5BXjxkioGSiv%2BGX8th69sGteYqSJjCkWlMl4Q9TMv6E1GZzoQrlCkvcKKBNIMiHEKSlSAVSGjmQ0QkldYlhmlNhLeclqMAixJOrqOV7YspVGBySRvngIzCohw2MbYTcIoypVGYl3C2MdR2Gaw2wAvD36%2FWYBFDLriK3IrZHK3jJDlhjkmRkKrCGmngDktmfXAeK%2BAFFhJbJIXBaffMXEXYyLOrOFru4yjPjoqHQdGjGWquU2hcViFZSwjuosvhl3YQgHZTAr2sRE5%2FGPNTabm9zGAQsHxBZaZlD%2B8Bzo8S9axM3QmEY8DZJXRyssOeIpOjlWjTPCozwhyVD7DYJoWQFZBLj1I6elwntMmOJNKzEjaBGdT87LaeRtzLr1s%2FnrexpxPzXB%2BZpkCYpyMiMeMpG3MxRnJ7zouAcUh6QoFcQkozklTKohLQNOUNk7YaQmoSRCl3OaczVKtKNUqx7Xv9HedauWGMlGuW3GeauVimYocd%2FNOrktvpEi1qjLc7cAVxalOVS55cZ7yJOE6%2BVyt4kg1dKpTbp5gGqn%2BVCeTu%2FsqQLk5jA7XAdCL000AsuL0LoAhcdoHsFqcbgNYqbYL4KTaPoCXavcBCqn2EKCUaocApVR7DFBJtafAk218TDoAotAjDIAkBIn4WCiSCYBGSJINgFZKugDopKQP0OynXTOZtk9d2%2FN%2FTJeo9KURw5YK9qCX29FxmSbPs7%2Bw%2BpIuQG8OgJsD0OYAMqoqb%2BfOdiZrVweQ0U0z7UZNrt1oFqA4pe3vP%2F%2FHMbOGCwAA%22%7D"
import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22czechtvPackagesOverrides%2Fstyles%2Fvanilla%2Fcomponents%2Fheader.css.ts.vanilla.css%22%2C%22source%22%3A%22Ll8xbjB3cWp2MCB7CiAgcGFkZGluZzogMCAwIHZhcigtLV8xOW1yaGFhMW8pIDA7CiAgbWFyZ2luOiB2YXIoLS1fMTltcmhhYTFxKSAwIHZhcigtLV8xOW1yaGFhMXEpIDA7CiAgY29sb3I6IHZhcigtLV8xOW1yaGFhMWspOwogIGJvcmRlci1ib3R0b206IDFweCBzb2xpZCB2YXIoLS1fMTltcmhhYTFlKTsKfQouXzFuMHdxanYxIHsKICBjb2xvcjogdmFyKC0tXzE5bXJoYWExayk7Cn0KQG1lZGlhIHNjcmVlbiBhbmQgKG1pbi13aWR0aDogNzY4cHgpIHsKICAuXzFuMHdxanYwIHsKICAgIG1hcmdpbjogdmFyKC0tXzE5bXJoYWExcykgMCB2YXIoLS1fMTltcmhhYTF1KSAwOwogIH0KfQpAbWVkaWEgc2NyZWVuIGFuZCAobWluLXdpZHRoOiAxMDI0cHgpIHsKICAuXzFuMHdxanYwIHsKICAgIG1hcmdpbjogMCAwIHZhcigtLV8xOW1yaGFhMXUpIDA7CiAgfQp9%22%7D"
import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22components%2FwebHistory%2FPageTypes%2FEventsPage%2FEventsPage.css.tsx.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAE71WTXOjMAy951d4Zi%2FlYAby2bqXPe3f6BgQwa2xPUZJm%2B70v%2B9gIAUHkmyzW27YlvSeniQ7fIrTEt%2FTTUx%2BzwjJtUJaiXdgZB7OVxbKxxkhUiigBYhtgfX6cRkQwdLK8FSoLSM0CqOjjfOU81LIAyN7bu8ofYofSltwvgzqA6mW2vpbB7dVcrsViqI2jETmrbeUaERd%2BlaxdmaGZ5kDEj3OPmZhx2zumDUOGIlI5FubwLNY%2FLXFsmcxBdIEQxMmeYU0LYTMxqz9AOYSKH0KyozH8J2vfO3jcFT6ONxcIe%2FqG%2BSdYrJ2TDJRGckPjOQSnPvnXYUiP9BUKwSFjNQ1CzQBfAVQ9QkuxVZRgVBWjKSgEGy9nPD0ZWv1TmW0JfTjl%2FsGgDy4KhgncITccE60zcBSyzOxq0Y594htfImi8H4zIdI1PRgFYx0chVF8tG7wMaK0gvpf77AO9Llwmhy0XFWGW1B4Jqkn8kwqSwjCG1LnhxEJOV4orXRnq3rPaNFFc5gECq0Y4VJ6ZvONL9Z8Pcz8vcv8q8iwYGTZatclu%2Fs3ugthQXIUe2jSm%2BNR7ltxPDgct3rhp60ej8%2F5OFxc0%2B3NMK%2BVZCQehEqGvdhVzah68UPw6OG6AVM05Jz2x6ttPJ3vtpQVeg%2F26xk%2FU6S9MNmXgVX77S3gmsKkq7oyJ7wbjsUtIXIh5QX60KffQDrPHr5HlvyruP6JKr4onfNM7BvnvYnoamYSyv%2BT8GcJmeCkSi2AIlxl5K4UirZDMo7mS%2FMWuNDh8H05aPFF28leky8%2BL7DxN%2Ba6t39xMn1chLuuh7iHdt2inbri9ckVb9poPSfJKeX%2BtD0z287yWgTNkeNkTaROX1qufwCe%2B8Wh0AsAAA%3D%3D%22%7D"
export var componentHeader = '_1n0wqjv0';
export var componentHeader_h1 = '_1cmtzc71';
export var hisotryItem = '_1cmtzc74';
export var hisotryItem_h3 = '_1cmtzc75';
export var historyBtn = '_1cmtzc77';
export var historyBtn_back = '_1cmtzc7e';
export var historyBtn_backSvg = '_1cmtzc7f';
export var historyBtn_div = '_1cmtzc7a';
export var historyBtn_next = '_1cmtzc7c';
export var historyBtn_nextSvg = '_1cmtzc7d';
export var historyBtn_span = '_1cmtzc7b';
export var historyBtn_svg = '_1cmtzc78';
export var historyBtn_svgPath = '_1cmtzc79';
export var historyBtns = '_1cmtzc76';
export var historyItems = '_1cmtzc73';
export var historySlider = '_1cmtzc72';