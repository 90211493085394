import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22czechtvPackagesOverrides%2Fstyles%2Fvanilla%2Fglobal%2Fglobal.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAE42WS2%2FbMAzH7%2FsUPK6AUojUw7Z2222HAcM67Do4jpu6y6tO0qYd9t0HJpbSmupatNJB%2FpF%2F8SG2oV%2Bvd%2FDnA8Bk8gurZX9T1zrA1XrfN%2B1Vvdp%2B69eT7%2B18v6h7Bdt6tZ1s2767%2FvTCAscWV%2B2y%2B7xezF43obHJl1296JrXDUyAHz8Zvmr6tl29fSk7Mvj%2FfdyI%2FtrOuv3ydd4H6OfT%2BmPlFaDXCsg5BXjxkioGSiv%2BGX8th69sGteYqSJjCkWlMl4Q9TMv6E1GZzoQrlCkvcKKBNIMiHEKSlSAVSGjmQ0QkldYlhmlNhLeclqMAixJOrqOV7YspVGBySRvngIzCohw2MbYTcIoypVGYl3C2MdR2Gaw2wAvD36%2FWYBFDLriK3IrZHK3jJDlhjkmRkKrCGmngDktmfXAeK%2BAFFhJbJIXBaffMXEXYyLOrOFru4yjPjoqHQdGjGWquU2hcViFZSwjuosvhl3YQgHZTAr2sRE5%2FGPNTabm9zGAQsHxBZaZlD%2B8Bzo8S9axM3QmEY8DZJXRyssOeIpOjlWjTPCozwhyVD7DYJoWQFZBLj1I6elwntMmOJNKzEjaBGdT87LaeRtzLr1s%2FnrexpxPzXB%2BZpkCYpyMiMeMpG3MxRnJ7zouAcUh6QoFcQkozklTKohLQNOUNk7YaQmoSRCl3OaczVKtKNUqx7Xv9HedauWGMlGuW3GeauVimYocd%2FNOrktvpEi1qjLc7cAVxalOVS55cZ7yJOE6%2BVyt4kg1dKpTbp5gGqn%2BVCeTu%2FsqQLk5jA7XAdCL000AsuL0LoAhcdoHsFqcbgNYqbYL4KTaPoCXavcBCqn2EKCUaocApVR7DFBJtafAk218TDoAotAjDIAkBIn4WCiSCYBGSJINgFZKugDopKQP0OynXTOZtk9d2%2FN%2FTJeo9KURw5YK9qCX29FxmSbPs7%2Bw%2BpIuQG8OgJsD0OYAMqoqb%2BfOdiZrVweQ0U0z7UZNrt1oFqA4pe3vP%2F%2FHMbOGCwAA%22%7D"
import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22components%2FwebCtCouncil%2FStaticContentComponents%2FComplaintsAndSuggestionsForm%2FComplaintsAndSuggestionsForm.css.tsx.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAE%2BVWzY7aMBC%2B8xTTQ1fl4MjJAgFz6ZtUhhiwNrG9joGwFe9e2fnBgSSwi9RDKwRInvE3v994gveToeYdw%2B8RQMJzldITgVUq12%2FLEUBG9ZYLtJLGyIzAgeofCP0KF5neURq%2Bj62OoknCxfZGqpx0RddvWy33IkFrmUp9rTYvtaROmEaaJnyf3yDJ8XJ0HgWlq6FzdSOFQTn%2FYATCIJ5qllmYlAuGdoxvd4ZAVB061Q3NeHq6Rp44451%2BncZe%2FEYqAlgV91Mi2ynBlxsEMOBrdTEuVQwrDKIp3woCKdsYL97IxTuAIXsxekILF85JqeiamxMBHMw9e6%2Bevb4olV%2BQSbt3tponFn5LLzmzZ8iwTKXUMNsH%2B0zkBMKNvpVqecwJ0L2R0JFu7Bmetg1vUuaM2X901Na8%2FbVHLiuIG5blBNZMGKYbF4d6bQqrvTFSOENHnpgdgXAe26AanVlLiPH3lizhh658uoIpmXPDpSCgWUoNPzB7%2BoG4SFhBIPKAYgfyAtT9D7YsgNFU1MhO8UovisfXJ7Pq6gvZyQPTlZWnoHqcjCvxeVR%2BfSJ3sTgMXht6DzAZO9imF7hIbbbrMXYn0yV9LMfRvGzYisDI8ojAK25VfO7SU%2BpHqoBvPFNSGyp82i4eo62nb%2FlLNStL3IS%2FaNum%2F0Mj4GDePdLDIHq4F1JmDNMot1POjeIAh83tui4dNQEMZUMMzc6ul7JA1RCIZ%2B2arT7ZCuubF%2B4JYoS9%2FrYG0qzlcdLyuOPNsp%2Fl3y7aswX5F3nTlIw98Gy33rYN5IqKVqFRrIq%2BHeU8%2BpmxhFPI15oxAVQk8CPjos7xbIJVMXZwgb9T3vNpX4XTt0WWO%2Bb5rv14Nu%2Bx34ecP4gc4mjySejj16HDCtpjVnQh0PWKG3iCK%2BogHGDv3t0t%2BL63tyWunfVXz8t6db4oRoOKg8NRV%2Buthzatc3TZ9ISsdz1Pb1bpVf7bnXLZXCQQfrWzJhVu1%2F6oq37uX3nrtddzdNGiSufEVUVXYsqx5iGtnkU6%2FwEh8XszEg4AAA%3D%3D%22%7D"
export var formContact = 'qytatq0';
export var formContactCheck = 'qytatq6';
export var formContactCheck_alert = 'qytatqf';
export var formContactCheck_span = 'qytatq7';
export var formContactCheck_spanGDPR = 'qytatq8';
export var formContactFileField = 'qytatqb';
export var formContactFileField_label = 'qytatqc';
export var formContactFileField_p = 'qytatqd';
export var formContactOneC = 'qytatq3';
export var formContactRadio = 'qytatqe';
export var formContactSendGroup = 'qytatq5';
export var formContactSubHead = 'qytatq2';
export var formContactTextArea = 'qytatq9';
export var formContactTextArea_p = 'qytatqa';
export var formContactTwoC = 'qytatq4';
export var formContact_h2 = 'qytatq1';